import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CommonPaths } from "../Routes/Paths";
import { logout } from "../Store/auth/authSlice";
import { resetState } from "../Store/common/commonSlice";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

const settings = ['Logout'];

const Settings = () => {
  const { SIGNIN_PATH } = CommonPaths;
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { name } = useSelector((state: any) => state.auth);

  const handleSetting = (setting: string) => {
    switch (setting) {
      case 'Logout':
        navigate(SIGNIN_PATH, { replace: true });
        dispatch(logout());
        dispatch(resetState());
        break;
      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={({ key }) => handleSetting(key.toString())}>
      {settings.map(item => (
        <Menu.Item key={item}>{item}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 0, ml: 'auto' }}>
      <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']} overlayStyle={{ zIndex: 9999 }}>
        <IconButton sx={{ p: 0 }}>
          <Avatar alt={name} sx={{ color: theme.palette.primary.dark, backgroundColor: theme.status.white }}>
            <Typography fontWeight={'bold'} fontSize={'1.5em'}>
              {name[0]}
            </Typography>
          </Avatar>
          <KeyboardArrowDown />        
        </IconButton>
      </Dropdown>
    </Box>
  );
};

export default Settings;
