import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useRoutes } from "react-router-dom";
import { AdminPermissions, CommonPermissions, SuperAdminPermissions } from "../Constants/Permissions";
import Layout from "../Main/Layout";
import Cohort from "../Pages/Admin/Cohort";
import CreateCohort from "../Pages/Admin/CreateCohort";
import Notfound from "../Pages/Common/NotFound";
import Sigin from "../Pages/Common/Sigin";
import CreateOrganization from "../Pages/SuperAdmin/CreateOrganization";
import CreateTraining from "../Pages/SuperAdmin/CreateTraining";
import CreateTrainingPackage from "../Pages/SuperAdmin/CreateTrainingPackage";
import CreateUser from "../Pages/SuperAdmin/CreateUser";
import Dashboard from "../Pages/SuperAdmin/Dashboard";
import Organization from "../Pages/SuperAdmin/Organization";
import TrainingPackage from "../Pages/SuperAdmin/TrainingPackage";
import Trainings from "../Pages/SuperAdmin/Trainings";
import Users from "../Pages/SuperAdmin/Users";
import { Route } from "../Types/Routes";
import AuthorisedRoute from "./AuthorisedRoutes";
import { AdminPaths, CommonPaths, SuperAdminPaths } from "./Paths";


const { SIGNIN_PATH, HOME_PATH } = CommonPaths;

const { ORGANIZATION_PATH,
 CREATE_USER,
 CREATE_ORGANIZATION_PATH,
 ORGANIZATION_DETAILS_PATH,
 TRAINING_PACKAGE,
 CREATE_TRAINING_PACKAGE,
 CREATE_TRAINING,
 TRAINING,
 TRAINING_DETAIL_PATH,
 TRAINING_PACKAGE_DETAIL_PATH } = SuperAdminPaths;

const { USERS_PATH,
 USERS_DETAILS_PATH,
 COHORT_PATH,
 CREATE_COHORT_PATH,
 COHORT_DETAILS_PATH } = AdminPaths;

const { VIEW_ORGANIZATION } = SuperAdminPermissions;

const { VIEW_USERS,
 VIEW_COHORT,
 CREATE_COHORT } = AdminPermissions;

const { VIEW_HOME } = CommonPermissions;



const AppRoutes = () => {

 const { permissions } = useSelector((state: any) => state.auth);


 const adminPrivateRoutes: Route[] = [
  {
   element: <Dashboard />,
   path: HOME_PATH,
   permission: VIEW_HOME
  },
  {
   path: ORGANIZATION_PATH,
   permission: VIEW_ORGANIZATION,
   children: [
    {
     index: true,
     element: <Organization />,
    },
    {
     element: <CreateOrganization />,
     path: CREATE_ORGANIZATION_PATH,
     permission: VIEW_ORGANIZATION
    },
    {
     element: <CreateOrganization />,
     path: ORGANIZATION_DETAILS_PATH,
     permission: VIEW_ORGANIZATION
    },
   ]
  },
  {
   path: USERS_PATH,
   permission: VIEW_USERS,
   children: [
    {
     index: true,
     element: <Users />,
    },
    {
     element: <CreateUser />,
     path: CREATE_USER,
     permission: VIEW_USERS
    },
    {
     element: <CreateUser />,
     path: USERS_DETAILS_PATH,
     permission: VIEW_USERS,
    }
   ]
  },
  {
   path: TRAINING_PACKAGE,
   permission: VIEW_USERS,
   children: [
    {
     index: true,
     element: <TrainingPackage />,
    },
    {
     element: <CreateTrainingPackage />,
     path: CREATE_TRAINING_PACKAGE,
     permission: VIEW_USERS
    },
    {
     element: <CreateTrainingPackage />,
     path: TRAINING_PACKAGE_DETAIL_PATH,
     permission: VIEW_USERS
    },
   ]
  },
  {
   path: TRAINING,
   permission: VIEW_USERS,
   children: [
    {
     index: true,
     element: <Trainings />,
    },
    {
     element: <CreateTraining />,
     path: CREATE_TRAINING,
     permission: VIEW_USERS
    },
    {
     element: <CreateTraining />,
     path: TRAINING_DETAIL_PATH,
     permission: VIEW_USERS
    },
   ]
  },
  {
   path: COHORT_PATH,
   permission: VIEW_COHORT,
   children: [
    {
     index: true,
     element: <Cohort />,
    },
    {
     element: <CreateCohort />,
     path: CREATE_COHORT_PATH,
     permission: VIEW_COHORT
    },
    {
     element: <CreateCohort />,
     path: COHORT_DETAILS_PATH,
     permission: VIEW_COHORT
    },
   ]
  }
 ];


 const PermissionedRoutes = adminPrivateRoutes.map((route: Route) => (
  permissions.includes(route.permission) ? route : { ...route, element: <Notfound /> }
 ));



 const adminRoutes = useMemo(() =>
  [
   {
    element: <Layout />,
    children: [
     {
      element: <AuthorisedRoute />,
      children: PermissionedRoutes
     }
    ]
   },
   {
    element: <Sigin />,
    path: SIGNIN_PATH
   },
   { element: <Notfound />, path: "*" },
  ],
  [PermissionedRoutes]);

 return useRoutes(adminRoutes);
};


export default AppRoutes;