
import { Alert } from "@mui/material";
import { useEffect, useState } from "react";
import { Error } from "../Types/MessageType";


const Message = ({ type, message, variant }: Error) => {

 const [alert, setAlert] = useState<Error>()

 useEffect(() => {
  setAlert({
   type, message
  })
 }, [message, type])


 return (
  <>
   <Alert variant={variant} severity={alert?.type}>{alert?.message}</Alert>
  </>
 )
}


export default Message