import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_TRAINING_MODULE } from "../../Graphql/Queries";
import { TrainingModuleType, TrainingModuleTypeResponse } from "../../Types/QueriesType";
import { valuesArr } from "../../Types/SelectType";
import FormItemSelect from "../FormItems/FormItemSelect";
const defaultImage: string = require("../../assets/defaultImage.svg").default;


type TrainingComponentType = {
 showTrainings?: boolean;
 label: string;
 name: string;
};



const TrainingModule = ({ showTrainings = false, label, name }: TrainingComponentType) => {

 const [options, setOptions] = useState<valuesArr[]>([]);

 useQuery(GET_TRAINING_MODULE, {
  variables: {
   page: 0,
   limit: 200
  },
  onCompleted(data: TrainingModuleTypeResponse) {
   const trainingPackage = data.getAllTrainingPackages.trainingPackages.map((trainingP: TrainingModuleType): valuesArr => {
    return {
     label: trainingP.name,
     value: trainingP._id
    };
   });
   setOptions(trainingPackage);
  }
 });


 return (
  <>
   <FormItemSelect label={label} name={name}  value={options} setValue={(v) => null} />
  </>
 );
};

export default TrainingModule;