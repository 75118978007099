// @ts-ignore
import { createUploadLink } from "apollo-upload-client"
import { ApolloLink, split } from "@apollo/client"
import { getMainDefinition } from "@apollo/client/utilities"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { createClient } from "graphql-ws"
import { ApolloClient, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import globals from "../Constants/Globals"
import store from '../Store/store'
import { GET_TOKEN_FROM_REFRESH_TOKEN } from "../Graphql/Queries"
import { setToken, logout } from "../Store/auth/authSlice";

const INVALID_SESSION_TOKEN = "INVALID_SESSION_TOKEN"

const { GRAPHQL_ENDPOINT, GRAPHQL_SUBSCRIPTION_ENDPOINT } = globals

const httpLink = createUploadLink({
 uri: GRAPHQL_ENDPOINT,
})

const wsLink = new GraphQLWsLink(
 createClient({
  url: GRAPHQL_SUBSCRIPTION_ENDPOINT + "",
 })
)

const authLink = setContext((_, { headers }) => {

 const token = store.getState().auth.token;

 return {
  headers: {
   ...headers,
   authorization: !!token ? `${token}` : "",
  },
 }
})

/**
 * This client instance is to avoid sending the token in the request
 */
const authClient = new ApolloClient({
 cache: new InMemoryCache(),
 uri: GRAPHQL_ENDPOINT,
 defaultOptions: {
  watchQuery: {
   fetchPolicy: "no-cache",
  },
 },
})

const errorlink = onError(({ graphQLErrors }) => {
 if (graphQLErrors) {
  const authState = store.getState()
  graphQLErrors.forEach(({ extensions }) => {
   if (extensions.code === INVALID_SESSION_TOKEN) {
    let refreshToken = authState.auth.refreshToken;
    authClient
     .query<any>({
      query: GET_TOKEN_FROM_REFRESH_TOKEN,
      variables: { refreshToken: refreshToken },
     })
     .then((res) => {
      if (!!res.data.refreshSessionToken.token) {
       store.dispatch(setToken(res.data.refreshSessionToken.token))
       window.location.reload()
      }
     })
     .catch((errors) => {
      if (!!errors) {
       store.dispatch(logout())
       window.location.reload()
      }
     })
   }
  })
 }
})

const httpLinkErrHandling = ApolloLink.from([errorlink, httpLink])

const splitLink = split(
 ({ query }) => {
  const definition = getMainDefinition(query)
  return (
   definition.kind === "OperationDefinition" &&
   definition.operation === "subscription"
  )
 },
 wsLink,
 authLink.concat(httpLinkErrHandling)
)

const client = new ApolloClient({
 link: splitLink,
 cache: new InMemoryCache(),
 defaultOptions: {
  watchQuery: {
   fetchPolicy: "no-cache",
  },
 },
})

export default client

export { authClient }
