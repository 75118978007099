
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { Modal, Upload, UploadFile, Form } from "antd"
import { RcFile } from "antd/es/upload";
import { useState } from "react";

type uploadImageProp = {
 name: string
 label: string

}

const UploadImage = ({ name, label }: uploadImageProp) => {

 const [imageUrl, setImageUrl] = useState<string>();
 const [previewOpen, setPreviewOpen] = useState<boolean>(false);
 const [fileList, setFileList] = useState<UploadFile[]>([]);
 const [err, setError] = useState<boolean>(false);
 const form = Form.useFormInstance();

 const uploadButton = (
  <div>
   <CameraAltOutlinedIcon />
   <div style={{ marginTop: 8 }}>Upload</div>
  </div>
 );


 const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
   setError(true)
  }
  const isLt2M = file.size < 1048576 ? true : false;
  if (!isLt2M) {
   setError(true)
  } else {
   setError(false)
  }
  return !isLt2M;
 };

 const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
   const reader = new FileReader();
   reader.readAsDataURL(file);
   reader.onload = () => resolve(reader.result as string);
   reader.onerror = (error) => reject(error);
  });

 const handlePreview = async (file: UploadFile) => {
  if (!file.url && !file.preview) {
   file.preview = await getBase64(file.originFileObj as RcFile);
  }

  setImageUrl(file.url || (file.preview as string));
  setPreviewOpen(true);
 };



 const handleChange = (info: any) => {
  let url = URL.createObjectURL(new Blob(info.fileList))
  if (!err) {
   setFileList(info.fileList)
   setTimeout(() => {
    form.setFieldValue(name, info.file)
   })
   setError(false);
   setImageUrl(url);
  } else {
   setError(true)
   //setAlert('')
  }

 };

 const handleCancel = () => setPreviewOpen(false);
 return (
  <>
   <Form.Item
    name={name}
    label={label}
    rules={[
     {
      required: false,
      message: "Image is required"
     },
     {
      validator(_, value) {
       if (err) {
        return Promise.reject('Image should be png/jpeg and size should be less then 1mb')
       } else {
        return Promise.resolve()
       }
      }
     }
    ]}
   >
    <Upload
     name="avatar"
     listType="picture-circle"
     fileList={fileList}
     showUploadList={true}
     beforeUpload={beforeUpload}
     onPreview={handlePreview}
     onChange={handleChange}
     onRemove={() => { setFileList([]); setError(false); setTimeout(() => { form.setFieldValue(name, undefined) }) }}
    >
     {fileList.length >= 1 ? null : uploadButton}
    </Upload>
   </Form.Item>
   <Modal open={previewOpen} footer={null} onCancel={handleCancel}>
    <img alt="example" style={{ width: '100%' }} src={imageUrl} />
   </Modal>
  </>
 )
}


export default UploadImage;