
import { Box } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Error } from "../../Types/MessageType";
import FormItemInput from "../FormItems/FormItemInput";
import TrainingModule from "../InputRequest/TrainingModule";
// import { useState } from "react";
// import { valuesArr } from "../../Types/SelectType";
// import { useQuery } from "@apollo/client";
// import { GET_ALL_TRAININGS } from "../../Graphql/Queries";

type createOrgProp = {
 alertMessage: Error;
 setTrainingsArray?: (val: string[]) => void;
 currentLocation: boolean;
};


const CreateCohortForm = ({ alertMessage, currentLocation, setTrainingsArray }: createOrgProp) => {



 // const setUpHandler = (tp: any) => {
 //  const trainingP = tp.map((t: any) => {
 //   return t._id;
 //  });
 //  setTrainingsArray!(trainingP);
 //  //form.setFieldValue("cohortTrainings", trainingP);
 // };

 // const onChange = (checked: boolean) => {
 //  setDisplay(checked);
 // };

 // const [display, setDisplay] = useState<boolean>(true);
 // const [options, setOptions] = useState<valuesArr[]>([]);
 // const { loading: trainingLoading } = useQuery<any>(GET_ALL_TRAININGS, {
 //  variables: {
 //   page: 0,
 //   limit: 1000
 //  },
 //  onCompleted(data) {
 //   if (data.getAllTrainings.trainings.length > 0) {
 //    const training = data?.getAllTrainings?.trainings;
 //    if (training.length > 0) {
 //     const _training = training.map((_singleT: any, index: number) => {
 //      return ({
 //       label: _singleT.name,
 //       value: _singleT._id
 //      });
 //     });
 //     setOptions(_training);
 //    }
 //   }
 //  },
 //  onError(err) {
 //   console.log(err.message);
 //  }
 // });

 return (
  <>
   <Grid2 container spacing={2} paddingX={3}>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Cohort Name" name="cohortName" rules={[{ required: true, message: 'Cohort name is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Cohort Start Name" name="cohortStartDate" type={'date'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <Box >
      <TrainingModule label="Training Module" name="cohortModule" />
     </Box>
    </Grid2>
   </Grid2>
  </>
 );
};

export default CreateCohortForm;