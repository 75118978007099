import { Button, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Form } from "antd";
import { Rule } from "antd/es/form";
import FormItemInput from "./FormItemInput";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, Stack } from "@mui/system";

type formListType = {
 formControlName: string
 label: string
 type: string
 rules: Rule[]
 gridXs: number
 gridSm: number
}

const FormListInput = ({ formControlName, label, rules, type, gridXs, gridSm }: formListType) => {
 return (
  <Form.List
   name={formControlName}
   initialValue={['']}
  >
   {(fields, { add, remove }) => {
    return (
     <>
      <Grid2 container spacing={2}>
       <Grid2 xs={gridXs} sm={gridSm}>
        <Typography variant="h6">
         Hardware Details
        </Typography>
       </Grid2>
       <Grid2 xs={gridXs} sm={gridSm} display="flex" justifyContent={'flex-end'}>
        <Button style={{ marginLeft: 'auto' }} variant="contained" onClick={() => add()}>Add</Button>
       </Grid2>
       {fields.map(({ key, name }, index) => {
        return (
         <Grid2 xs={gridXs} sm={4} key={key}>
          <Stack direction={'row'} alignItems={'center'} spacing={2}>
           <Box sx={{ flexGrow: 1 }}>
            <FormItemInput label={label} name={`${name}`} rules={rules} type={type} />
           </Box>
           <Box onClick={() => remove(index)}><DeleteForeverIcon /></Box>
          </Stack>
         </Grid2>
        )
       })}
      </Grid2>
     </>
    )

   }
   }
  </Form.List >
 )
}

export default FormListInput;