
import { Form, TabsProps } from 'antd';
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CreateTabs from "../../Components/CreateTabs";
import { CREATE_TRAINING_PACKAGE } from '../../Graphql/Mutations';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Error } from '../../Types/MessageType';
import LoadingButton from '@mui/lab/LoadingButton';
import Message from '../../Components/Message';
import CreateTrainingPackageForm from '../../Components/SubPages/CreateTrainingPackageFrom';
import { useLocation, useNavigate } from 'react-router-dom';
import { SuperAdminPaths } from '../../Routes/Paths';
import { GET_TRAINING_MODULE__BY_ID } from '../../Graphql/Queries';
import { valuesArr } from '../../Types/SelectType';




const CreateTrainingPackage = () => {

  const [form] = Form.useForm();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState<string>('1');
  const navigate = useNavigate();
  const { TRAINING_PACKAGE } = SuperAdminPaths;
  const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });

  const [getTrainingPackageById] = useLazyQuery(GET_TRAINING_MODULE__BY_ID, {
    variables: {
      trainingPackageByIdId: location.state?.trainingPackageId
    },
    onCompleted(data) {
      const { name } = data.trainingPackageById;
      const trainings = data.trainingPackageById.trainings.map((_singleT: any, index: number) => {
        return ({
          label: _singleT.name,
          value: _singleT._id
        });
      });
      form.setFieldsValue({ name, trainings });
    }
  });


  useEffect(() => {
    if (!!location.state?.trainingPackageId) {
      getTrainingPackageById();
    }
  }, [location.state?.trainingPackageId, getTrainingPackageById]);

  const [createTrainingPackage, { loading }] = useMutation(CREATE_TRAINING_PACKAGE, {
    onCompleted() {
      setAlert({
        type: "success",
        message: "Training package created successfully"
      });
      navigate(TRAINING_PACKAGE);
    },
    onError(error) {
      setAlert({
        type: "error",
        message: error.message
      });
    }
  });

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <h3>Package Details</h3>,
      children: <CreateTrainingPackageForm alertMessage={alert} />
    },
  ];


  const createTrainingPackageHandler = (value: any) => {
    let trainingData;
    if (location.state?.trainingPackageId) {
      const trainingsId = value.trainings.map((training: valuesArr) => {
        if (!!training.value) {
          return training.value;
        } else {
          return training;
        }
      });

      trainingData = { ...value, trainings: trainingsId, _id: location.state?.trainingPackageId };
    } else {
      trainingData = { ...value };
    }
    createTrainingPackage({
      variables: {
        trainingPackageInput: trainingData
      }
    });
  };


  return (
    <>
      <Form
        onFinish={createTrainingPackageHandler}
        form={form}
        layout={'vertical'}
      >
        <Box sx={{ mb: 3 }} display={'flex'} justifyContent={'flex-end'} >
          <Box flexGrow={1} sx={{ pr: 3 }}>
            {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
          </Box>
          {
            !loading ? <>
              <Stack direction={'row'} spacing={2}>
                <Button
                  sx={{ px: 5, py: 1 ,color: 'white',height: '45px'}}
                  variant="contained"
                  type='submit'>
                  {location.state?.trainingPackageId ? 'Update' : 'Save'}</Button>
              </Stack>
            </> :
              <>
                <LoadingButton
                  sx={{ px: 5, py: 1 }}
                  loading
                  variant="contained">
                  <span>Save</span>
                </LoadingButton>
              </>
          }
        </Box>
        <CreateTabs items={items} heading={'Create Package'} headingStyle={{ color: 'black' }} keySelected={selectedKey} defaultKey={'1'} selectedTab={(tab) => null} />
      </Form>
    </>
  );
};

export default CreateTrainingPackage;