
import { Form, TabsProps,Typography } from 'antd';
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CreateTabs from "../../Components/CreateTabs";
import CreateOrgForm from '../../Components/SubPages/CreateOrgForm';
import CreateHardwareDetailForm from '../../Components/SubPages/CreateHardwareDetailForm';
import { CREATE_ORGANIZATION, UPDATE_ORGANIZATION } from '../../Graphql/Mutations';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Error } from '../../Types/MessageType';
import globals from '../../Constants/Globals';
import LoadingButton from '@mui/lab/LoadingButton';
import Message from '../../Components/Message';
import { GET_ORG_BY_ID } from '../../Graphql/Queries';
import { useLocation, useNavigate } from 'react-router-dom';
import { SuperAdminPaths } from '../../Routes/Paths';
import { useSelector, useDispatch } from 'react-redux';


const CreateOrganization = () => {

  const [tab, setTab] = useState<boolean>(true)
  const [selectedKey, setSelectedKey] = useState<string>('1')
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });
  const [createOrganization, { loading: loadingOrganization }] = useMutation(CREATE_ORGANIZATION)
  const [updateOrganization, { loading: loadingOrg }] = useMutation(UPDATE_ORGANIZATION)
  const location = useLocation();
  const navigate = useNavigate();
  const [getOrgaDataById] = useLazyQuery(GET_ORG_BY_ID, {
    variables: {
      organizationByIdId: location.state?.orgId
    },
    onCompleted(data) {
      const trainingPackage = data.organizationById.trainingPackage?._id; 
      const training = data.organizationById.training?._id; 
      const orgData = { ...data.organizationById, trainingPackage, training };
      form.setFieldsValue(orgData);
    }
  })


  useEffect(() => {
    if (!!location.state?.orgId) {
      getOrgaDataById()
    }
  }, [location.state?.orgId, getOrgaDataById])


  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <h3>Company Information</h3>,
      children: <CreateOrgForm alertMessage={alert} />
    },
    {
      key: '2',
      label: <h3>Hardware Details</h3>,
      children: <CreateHardwareDetailForm alertMessage={alert} />
    },
  ];


  const createOrganizationHandler = (value: any) => {
    form.setFieldValue('userLicenseCount', +value.userLicenseCount)
    const orgData = form.getFieldsValue();
    if (!!location.state?.orgId) {
      updateOrganization({
        variables: {
          org: { ...orgData,_id:location.state?.orgId }
        },
        onCompleted() {
          setAlert({ type: "success", message: "Organization updated successfully" })
          form.resetFields();
          setTimeout(() => {
            setAlert({
              type: 'success',
              message: ''
            });
            navigate(SuperAdminPaths.ORGANIZATION_PATH);
          }, globals.timeout)
        },
        onError(error) {
          setAlert({ type: 'error', message: error.message })
          setTimeout(() => {
            setAlert({
              type: 'error',
              message: ''
            })
          }, globals.timeout);
        }
      })
    } else {
      createOrganization({
        variables: {
          org: orgData
        },
        onCompleted() {
          setAlert({ type: "success", message: "Organization created successfully" })
          form.resetFields();
          setTimeout(() => {
            setAlert({
              type: 'success',
              message: ''
            });
            navigate(SuperAdminPaths.ORGANIZATION_PATH);
          }, globals.timeout)
        },
        onError(error) {
          setAlert({ type: 'error', message: error.message })
          setTimeout(() => {
            setAlert({
              type: 'error',
              message: ''
            })
            navigate(SuperAdminPaths.ORGANIZATION_PATH);
          }, globals.timeout);
        }
      })
    }
  }

  const showContinueButton =
    <Button
      onClick={() => { setSelectedKey('2'); setTab(false) }}
      sx={{ px: 5, py: 1,color: 'white' ,height: '45px'}}
      variant="contained" >
      Continue</Button>

  const showSaveButton = !loadingOrganization && !loadingOrg ?
    <>
      <Stack direction={'row'} spacing={2}>
        <Button
          sx={{ px: 5, py: 1,color: 'white',height: '45px' }}
          variant="contained"
          onClick={() => { setSelectedKey('1'); setTab(true) }}>
          Back</Button>
        <Button
          sx={{ px: 5, py: 1 ,color: 'white',height: '45px'}}
          variant="contained"
          type='submit'>
          {location.state?.orgId ? 'Update' : 'Save'}</Button>
      </Stack>
    </> :
    <>
      <LoadingButton
        sx={{ px: 5, py: 1 }}
        loading
        variant="contained">
        <span>Save</span>
      </LoadingButton>
    </>

  return (
    <>
      <Form
        onFinish={createOrganizationHandler}
        form={form}
        layout={'vertical'}
      >
        <Box sx={{ mb: 3 }} display={'flex'} justifyContent={'flex-end'} >
          <Box flexGrow={1} sx={{ pr: 3 }}>
            {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
          </Box>
          {
            tab ? showContinueButton : showSaveButton
          }
        </Box>
        <CreateTabs items={items} heading={'Organization Details'} headingStyle={{ color: 'black' }} keySelected={selectedKey} defaultKey={'1'} selectedTab={(tab) => null} />
      </Form>
    </>
  )
}

export default CreateOrganization;