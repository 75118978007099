import { Navigate, Outlet, useLocation } from "react-router-dom"
//import { Spin } from "antd"
import { useSelector } from "react-redux";
import { CommonPaths } from "./Paths";

function AuthorisedRoute() {
 const { loggedIn } = useSelector((state: any) => state.auth)
 const location = useLocation()

 // if (checkingStatus) {
 //  return <Spin />
 // }
 return (
  <>
   {loggedIn ? (
    <Outlet />
   ) : (
    <Navigate to={CommonPaths.SIGNIN_PATH} state={{ from: location }} />
   )}
  </>
 )
}

export default AuthorisedRoute
