
import { Form, TabsProps } from 'antd';
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CreateTabs from "../../Components/CreateTabs";
import { useLazyQuery, useMutation } from '@apollo/client';
import { Error } from '../../Types/MessageType';
import LoadingButton from '@mui/lab/LoadingButton';
import Message from '../../Components/Message';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateTrainingForm from '../../Components/SubPages/CreateTrainingForm';
import { SuperAdminPaths } from '../../Routes/Paths';
import { CREATE_TRAINING } from '../../Graphql/Mutations';
import { GET_TRAINING_BY_ID } from '../../Graphql/Queries';


const CreateTraining = () => {

 const [form] = Form.useForm();
 const location = useLocation();
 const [selectedKey, setSelectedKey] = useState<string>('1');
 const navigate = useNavigate();
 const { TRAINING } = SuperAdminPaths;
 const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });

 const [getTrainingById] = useLazyQuery(GET_TRAINING_BY_ID, {
  variables: {
   trainingByIdId: location.state?.trainingId
  },
  onCompleted(data) {
   const trainingData = { ...data.trainingById };
   form.setFieldsValue(trainingData);
  }
 });


 useEffect(() => {
  if (!!location.state?.trainingId) {
   getTrainingById();
  }
 }, [location.state?.trainingId, getTrainingById]);

 const [createTrainingPackage, { loading }] = useMutation(CREATE_TRAINING, {
  onCompleted() {
   setAlert({
    type: "success",
    message: "Training created successfully"
   });
   navigate(TRAINING);
  },
  onError(error) {
   setAlert({
    type: "error",
    message: error.message
   });
  }
 });



 const items: TabsProps['items'] = [
  {
   key: '1',
   label: <h3>Training Details</h3>,
   children: <CreateTrainingForm alertMessage={alert} />
  },
 ];


 const createTrainingHandler = (value: any) => {
  let trainingData;
  if (location.state?.trainingId) {
   trainingData = { ...value, _id: location.state?.trainingId };
  } else {
   trainingData = { ...value };
  }
  createTrainingPackage({
   variables: {
    trainingInput: trainingData
   }
  });
 };


 return (
  <>
   <Form
    onFinish={createTrainingHandler}
    form={form}
    layout={'vertical'}
   >
    <Box sx={{ mb: 3 }} display={'flex'} justifyContent={'flex-end'} >
     <Box flexGrow={1} sx={{ pr: 3 }}>
      {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
     </Box>
     {
      !loading ? <>
       <Stack direction={'row'} spacing={2}>
        <Button
         sx={{ px: 5, py: 1,color: 'white' ,height: '45px'}}
         variant="contained"
         type='submit'>
         {location.state?.trainingId ? 'Update' : 'Save'}</Button>
       </Stack>
      </> :
       <>
        <LoadingButton
         sx={{ px: 5, py: 1 }}
         loading
         variant="contained">
         <span>Save</span>
        </LoadingButton>
       </>
     }
    </Box>
    <CreateTabs items={items} heading={'Create Training'} headingStyle={{ color: 'black' }} keySelected={selectedKey} defaultKey={'1'} selectedTab={(tab) => null} />
   </Form>
  </>
 );
};

export default CreateTraining;