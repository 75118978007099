
enum SuperAdminPaths {
 ORGANIZATION_PATH = "/organizations",
 TRAINING_PACKAGE = "/training-package",
 TRAINING = "/trainings",
 CREATE_ORGANIZATION_PATH = "create-organization",
 CREATE_USER = "create-user",
 CREATE_TRAINING_PACKAGE = "create-package",
 CREATE_TRAINING = "create-training",
 TRAINING_DETAIL_PATH = ":id",
 TRAINING_PACKAGE_DETAIL_PATH = ":id",
 ORGANIZATION_DETAILS_PATH = ":id",
}

enum AdminPaths {
 USERS_PATH = "/users",
 COHORT_PATH = "/cohort",
 CREATE_COHORT_PATH = "create-cohort",
 USERS_DETAILS_PATH = ':id',
 COHORT_DETAILS_PATH = ":id",
}


enum CommonPaths {
 HOME_PATH = "/",
 SIGNIN_PATH = "/sigin",
 UPDATE_PASSWORD_PATH = "/update-password/:token",
 FORGOT_PASSWORD_PATH = "/forgot-password",
}

export { CommonPaths, SuperAdminPaths, AdminPaths };
