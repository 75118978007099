import React, { useState } from 'react';
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import Settings from "../Components/Settings";
import { LayoutProps, SideBar } from "../Types/LayoutType";
import DashboardIcon from '@mui/icons-material/Dashboard';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AdminPaths, CommonPaths, SuperAdminPaths } from "../Routes/Paths";
import { Image } from "antd";
import Logo from '../assets/IQdash_logo.svg';
import { AdminPermissions, SuperAdminPermissions } from "../Constants/Permissions";
import { useSelector } from "react-redux";
import BookmarkIcon from '@mui/icons-material/Bookmark';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';

const { HOME_PATH } = CommonPaths;
const { ORGANIZATION_PATH, TRAINING_PACKAGE, TRAINING } = SuperAdminPaths;
const { USERS_PATH, COHORT_PATH } = AdminPaths;
const { VIEW_DASHBOARD_SA, VIEW_ORGANIZATION } = SuperAdminPermissions;
const { VIEW_DASHBOARD_A, VIEW_USERS, VIEW_COHORT } = AdminPermissions;

const Sidebar = ({ drawerWidth, mobileOpenBool, setMobileToggle, window }: LayoutProps) => {
 const [selectedItem, setSelectedItem] = useState('');
 const container = window !== undefined ? () => window().document.body : undefined;
 const navigate = useNavigate();
 const location = useLocation();
 const { permissions } = useSelector((state: any) => state.auth);
 const theme = useTheme();

 const handleListItemClick = (
  event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  path: string,
 ) => {
  setSelectedItem(path);
  navigate(path);
 };

 const items: SideBar[] = [
  {
   name: 'Dashboard',
   iconWhite: <DashboardIcon style={{ color: theme.status.white }} />,
   iconDark: <DashboardIcon style={{ color: theme.status.black }} />,
   path: HOME_PATH,
   permission: VIEW_DASHBOARD_SA,
  },
  {
   name: 'Dashboard',
   iconWhite: <DashboardIcon style={{ color: theme.status.white }} />,
   iconDark: <DashboardIcon  style={{ color: theme.status.black }}/>,
   path: HOME_PATH,
   permission: VIEW_DASHBOARD_A
  },
  {
   name: 'Organization',
   iconWhite: <CorporateFareIcon style={{ color: theme.status.white }} />,
   iconDark: <CorporateFareIcon style={{ color: theme.status.black }} />,
   path: ORGANIZATION_PATH,
   permission: VIEW_ORGANIZATION
  },
  {
   name: 'Users',
   iconWhite: <SupervisedUserCircleIcon style={{ color: theme.status.white }} />,
   iconDark: <SupervisedUserCircleIcon style={{ color: theme.status.black }} />,
   path: USERS_PATH,
   permission: VIEW_USERS
  },
  {
   name: 'Training Package',
   iconWhite: <BookmarkIcon style={{ color: theme.status.white }} />,
   iconDark: <BookmarkIcon style={{ color: theme.status.black }} />,
   path: TRAINING_PACKAGE,
   permission: VIEW_USERS
  },
  {
   name: 'Trainings',
   iconWhite: <CollectionsBookmarkIcon style={{ color: theme.status.white }} />,
   iconDark: <CollectionsBookmarkIcon style={{ color: theme.status.black }} />,
   path: TRAINING,
   permission: VIEW_USERS
  },
  {
   name: 'Cohort',
   iconWhite: <SupervisedUserCircleIcon style={{ color: theme.status.white }} />,
   iconDark: <SupervisedUserCircleIcon style={{ color: theme.status.black }} />,
   path: COHORT_PATH,
   permission: VIEW_COHORT
  }
 ];

 const comparePathHandler = (urlPath: string, selectedPath: string): boolean => {
  return urlPath.split('/').slice(1).includes(selectedPath.substring(1));
 };

 const drawer = (
  <div>
   <Box sx={{ pt: 1 }}>
    <Link to={CommonPaths.HOME_PATH}>
     <Image src={Logo} alt="logo" preview={false} width="90%" height={`70px`} style={{marginRight:'10px',marginLeft:'10px'}}/>
    </Link>
   </Box>
   <Divider />
   <List>
    {items.map((item, index) => (
     permissions.includes(item.permission) &&
     (
      <ListItem key={index} disablePadding>
       <ListItemButton selected={comparePathHandler(location.pathname, item.path)}
        onClick={(event) => handleListItemClick(event, item.path)} sx={{ color: comparePathHandler(location.pathname, item.path) ? theme.status.white : theme.status.black, background: selectedItem === item.path ? 'linear-gradient(123.76deg, #F2709C 9.95%, #FF9472 86.42%)' : 'transparent' }}>
        <ListItemIcon>
         {comparePathHandler(location.pathname, item.path) ? item.iconWhite : item.iconDark}
        </ListItemIcon>
        <ListItemText primary={item.name} />
       </ListItemButton>
      </ListItem>
     )
    ))}
   </List>
  </div>
 );

 return (
  <>
   <Box
    component="nav"
    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
    aria-label="sidebar"
   >
    <Drawer
     container={container}
     variant="temporary"
     open={mobileOpenBool}
     onClose={() => setMobileToggle(!mobileOpenBool)}
     ModalProps={{
      keepMounted: true, // Better open performance on mobile.
     }}
     sx={{
      display: { xs: 'block', sm: 'none' },
      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
     }}
    >
     {drawer}
    </Drawer>
    <Drawer
     variant="permanent"
     sx={{
      display: { xs: 'none', sm: 'block' },
      '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
     }}
     open
    >
     {drawer}
    </Drawer>
   </Box>
  </>
 );
};

export default Sidebar;
