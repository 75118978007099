import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import Stats from "../Stats"


const SuperAdminDashboard = () => {

 return (
  <>
   <Grid2 container spacing={2}>
    <Grid2 xs={12} sm={4}>
     <Stats text="Total Organization" number={20} />
    </Grid2>
    <Grid2 xs={12} sm={4}>
     <Stats text="Total Package" number={20} />
    </Grid2>
    <Grid2 xs={12} sm={4}>
     <Stats text="Total Users" number={20} />
    </Grid2>
   </Grid2>
  </>
 )
}

export default SuperAdminDashboard