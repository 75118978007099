import { useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useSelector } from "react-redux"; // Or your state management library
import { Link } from "react-router-dom";
import Card from "../../Components/Cards/Card";
import DataTable from "../../Components/Datatable";
import Message from "../../Components/Message";
import Stats from "../../Components/Stats";
import TableHeading from "../../Components/TableHeading";
import globals from "../../Constants/Globals";
import { GET_ALL_TRAININGS, GET_USER_BY_ID, GET_ORG_BY_ID, GET_TRAINING_MODULE__BY_ID } from "../../Graphql/Queries";
import { SuperAdminPaths } from "../../Routes/Paths";
import { pages } from "../../Types/DatatableType";
import defaultImage from '../../assets/defaultImage.svg';  // Corrected import path

const Trainings = () => {
  const { CREATE_TRAINING } = SuperAdminPaths;

  const [tableData, setTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<pages>();
  const [alert, setAlert] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isRootAdmin, setIsRootAdmin] = useState<boolean>(false);
  const [trainingPackageId, setTrainingPackageId] = useState<string | null>(null);

  const userId = useSelector((state: any) => state.auth.userId);

  const { data: userData, loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: { userWithRolesByIdId: userId },
    onCompleted(data) {
      const roles = data?.userWithRolesById?.roles || [];
      const isAdmin = roles.some((role: any) => role.roleCode === "ROOT_ADMIN");
      setIsRootAdmin(isAdmin);
      const orgId = data?.userWithRolesById?.organization || null;
      if (!isAdmin && orgId) {
        setTrainingPackageId(orgId); 
      }
    },
    onError(err) {
      console.error("Error fetching user data:", err.message);
    }
  });

  // Fetch organization details to get trainingPackageId if not a root admin
  useQuery(GET_ORG_BY_ID, {
    skip: isRootAdmin || !trainingPackageId,
    variables: { organizationByIdId: trainingPackageId },
    onCompleted(data) {
      const trainingPackage = data?.organizationById?.trainingPackage?._id || null;
      setTrainingPackageId(trainingPackage);
    },
    onError(err) {
      console.error("Error fetching organization data:", err.message);
    }
  });

  // Query for root admin
  const { loading: trainingLoading } = useQuery(GET_ALL_TRAININGS, {
    skip: !isRootAdmin,
    variables: {
      page: currentPage,
      limit: globals.pageSize,
    },
    onCompleted(data) {
      if (data.getAllTrainings.trainings.length > 0) {
        const training = data?.getAllTrainings?.trainings;
        setTotalPages({
          currentPage: data.getAllTrainings?.currentPage,
          limit: globals.pageSize,
          totalDocs: data.getAllTrainings?.totalDocs,
          totalPages: data.getAllTrainings?.totalPages,
        });
        setTableData([]);
        if (training.length > 0) {
          const _training = training.map((_singleT: any, index: number) => {
            return {
              key: index,
              _id: _singleT._id,
              name: _singleT.name,
              description: _singleT.description,
              difficultyLevel: _singleT.difficultyLevel,
              typeOfTraining: _singleT.typeOfTraining,
              thumbnail: _singleT.thumbnail || defaultImage, // Use default image if no thumbnail
            };
          });
          setTableData(_training);
        }
      }
    },
    onError(err) {
      console.log("Error fetching all trainings:", err.message);
      setAlert(err.message);
    },
  });

  // Query for non-root admin
  const { loading: trainingPackageLoading } = useQuery(GET_TRAINING_MODULE__BY_ID, {
    skip: isRootAdmin || !trainingPackageId,
    variables: { trainingPackageByIdId: trainingPackageId },
    onCompleted(data) {
      const trainingPackage = data?.trainingPackageById?.trainings || [];
      setTotalPages({
        currentPage,
        limit: globals.pageSize,
        totalDocs: trainingPackage.length,
        totalPages: Math.ceil(trainingPackage.length / globals.pageSize),
      });
      const _training = trainingPackage.map((_singleT: any, index: number) => {
        return {
          key: index,
          _id: _singleT._id,
          name: _singleT.name,
          description: _singleT.description,
          difficultyLevel: _singleT.difficultyLevel,
          typeOfTraining: _singleT.typeOfTraining,
          thumbnail: _singleT.thumbnail || defaultImage, // Use default image if no thumbnail
        };
      });
      setTableData(_training);
    },
    onError(err) {
      console.log("Error fetching training package:", err.message);
      setAlert(err.message);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: (
        <TableHeading heading="Thumbnail" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "thumbnail",
      render: (text) => (
        <img src={text} alt="Thumbnail" style={{ width: "60px", height: "auto", display: "block", margin: "auto", marginTop: "-15px", marginBottom: "-15px" }} />
      ),
      key: "1",
      width: "20%",
    },
    {
      title: (
        <TableHeading heading="Training Name" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "name",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "2",
      width: "20%",
    },
    {
      title: (
        <TableHeading heading="Type Of Training" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "typeOfTraining",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "3",
      width: "20%",
    },
    {
      title: (
        <TableHeading heading="Difficulty Level" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "difficultyLevel",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "4",
      width: "20%",
    },
    {
      title: '',
      render: (_, record) => <Button variant="contained" sx={{ alignSelf: 'flex-end' }}><Link style={{ textDecoration: 'none', color: 'white' }} to={record._id} state={{ trainingId: record._id }}>View Details</Link></Button>,
      key: "5",
      width: "20%",
    },
  ];

  const loading = userLoading || (isRootAdmin ? trainingLoading : trainingPackageLoading);

  return (
    <>
      <Stats buttonText="Create Training" text="Total Trainings" number={tableData.length} link={CREATE_TRAINING} />
      <Card>
        {
          !!alert ?
            <Message type="warning" message={alert} /> :
            <DataTable
              loading={loading}
              tableData={tableData}
              totalPages={totalPages}
              showCheck={false}
              columnData={columns}
              page={(cp) => {
                setCurrentPage(Number(cp));
              }}
              scrollX="max-content"
              scrollY={450}
            />
        }
      </Card>
    </>
  );
};

export default Trainings;