import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';
import { Paper, useTheme } from '@mui/material';
import BasicBreadcrumbs from '../Components/Breadcrumbs';


interface Props {
 window?: () => Window;
}

export default function Layout(props: Props) {
 const { window } = props;
 const theme = useTheme();
 const [mobileOpen, setMobileOpen] = React.useState(false);

 return (
  <Box sx={{ display: 'flex', width: "100%" }}>
   <Header drawerWidth={theme.status.drawerWidth} mobileOpenBool={mobileOpen} setMobileToggle={(boolean: boolean) => { setMobileOpen(boolean) }} />
   <Sidebar drawerWidth={theme.status.drawerWidth} mobileOpenBool={mobileOpen} setMobileToggle={(boolean: boolean) => { setMobileOpen(boolean) }} window={window} />
   <Box
    component="main"
    sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${theme.status.drawerWidth}px)`, xs: "100%" } }}
   >
    <Toolbar />
    <Paper elevation={2} sx={{ backgroundColor: theme.palette.primary.main, color: theme.status.white, p: 1, mb: 3,marginTop:'15px' }}>
     <BasicBreadcrumbs />
    </Paper>
    <Outlet />
   </Box>
  </Box>
 );
}