import { createSlice } from "@reduxjs/toolkit";
import { commonIntialState } from "../../Types/authIntialState";


const initialState: commonIntialState = {
 sidebarSelected: 0
}


const commonSlice = createSlice({
 name: 'common',
 initialState: initialState,
 reducers: {
  setState: (state, { payload }) => {
   state.sidebarSelected = payload?.index
  },
  resetState: (state) => {
   state.sidebarSelected = initialState.sidebarSelected
  }
 }
})

export const { setState, resetState } = commonSlice.actions

export default commonSlice.reducer;
