import { useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useSelector } from "react-redux";
import globals from "../../Constants/Globals";
import { GET_LEADERBOARD_DATA } from "../../Graphql/Queries";
import { pages } from "../../Types/DatatableType";
import Card from "../Cards/Card";
import DataTable from "../Datatable";
import Stats from "../Stats";
import TableHeading from "../TableHeading";


const AdminDashboard = () => {
 let count = 0;
 const { organization } = useSelector((state: any) => state.auth);
 const [tableData, setTableData] = useState<any[]>([]);
 // const [totalPages, setTotalPages] = useState<pages>();
 const [currentPage, setCurrentPage] = useState<number>(1);
 const { loading: leaderBoardLoading } = useQuery<any>(GET_LEADERBOARD_DATA, {
  variables: {
   orgId: organization
  },
  onCompleted(data) {
   if (data.leaderBoard.length > 0) {
    const topRankers = data?.leaderBoard;
    // setTotalPages({
    //  currentPage: data?.allOrganizations?.currentPage,
    //  limit: globals.pageSize,
    //  totalDocs: data.allOrganizations?.totalDocs,
    //  totalPages: data.allOrganizations?.totalPages,
    // });
    setTableData([]);
    if (topRankers.length > 0) {
     const _topRankers = topRankers.map((_singleRanker: any, index: number) => {
      return {
       key: index,
       userId: _singleRanker._id,
       rank: ++count,
       userName: _singleRanker.name,
       noOfTrainings: _singleRanker.totalTrainings,
       trainingCompleted: _singleRanker.trainingCompleted,
       accuracy: _singleRanker.accuracy,
      };
     });
     setTableData(_topRankers);
    }
   }
  }
 });

 const columns: ColumnsType<any> = [
  {
   title: (
    <TableHeading heading=" Name" setSearch={(e) => console.log(e)} setPage={setCurrentPage}/>
   ),
   dataIndex: "userName",
   key: "1",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
  },
  {
   title: (
    <TableHeading heading=" Rank" setSearch={(e) => console.log(e)} setPage={setCurrentPage}/>
   ),
   dataIndex: "rank",
   key: "2",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
  },
  {
   title: (
    <TableHeading heading="Number of trainings" setSearch={(e) => console.log(e)} setPage={setCurrentPage}/>
   ),
   dataIndex: "noOfTrainings",
   key: "3",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
  },
  {
   title: (
    <TableHeading heading="Trainings completed" setSearch={(e) => console.log(e)} setPage={setCurrentPage}/>
   ),
   dataIndex: "trainingCompleted",
   key: "4",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
  },
  {
   title: (
    <TableHeading heading="Overall accuracy" setSearch={(e) => console.log(e)} setPage={setCurrentPage}/>
   ),
   dataIndex: "accuracy",
   key: "5",
   render: (value) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{`${parseFloat(value).toFixed(2)}%`}</Typography>
  }
 ];


 return (
  <>
   <Stats buttonText="Create Organization" text="Total Users" number={tableData.length} />
   <Card>
    <DataTable
     loading={leaderBoardLoading}
     tableData={tableData}
     showCheck={false}
     columnData={columns}
     scrollX="max-content"
    />
   </Card>
  </>
 );
};

export default AdminDashboard;