import { createSlice } from "@reduxjs/toolkit";
import { InitialState, org } from "../../Types/authIntialState";

const orgn:org={
   _id:'',
   name:''
}
const initialState: InitialState = {
 loggedIn: false,
 tokenTimeout: 0,
 token: '',
 permissions: [],
 refreshToken: '',
 email: '',
 name: '',
 userId: '',
 organization: '',
 allOrganizations:[orgn]
}


const authSlice = createSlice({
 name: 'auth',
 initialState: initialState,
 reducers: {
  login: (state, { payload }) => {
   state.loggedIn = true;
   state.token = payload.token;
   state.permissions = payload.permissions;
   state.refreshToken = payload.refreshToken;
   state.email = payload.email;
   state.name = payload.name;
   state.userId = payload.userID;
   state.organization = payload.organization;
  },
  logout: (state) => {
   state.loggedIn = false;
   state.token = '';
   state.permissions = [];
   state.refreshToken = '';
   state.email = '';
   state.name = '';
   state.userId = '';
   state.organization = ''
  },
  setToken: (state, { payload: token }) => {
   state.token = token;
  }
 }
})

export const { login, logout, setToken } = authSlice.actions

export default authSlice.reducer;
