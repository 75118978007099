import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, gql, useLazyQuery } from "@apollo/client";
import { Box, Chip, CircularProgress, Typography, Paper, Divider, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useLocation } from "react-router-dom";
import { GET_USER_ANALYTICS, GET_QUESTION_DETAILS, GET_USER_BY_ID, GET_ORG_BY_ID, GET_TRAINING_MODULE__BY_ID } from "../../Graphql/Queries";
import CancelIcon from '@mui/icons-material/Cancel';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Form, Tag, Tooltip } from "antd";
import FormItemSelect from "../FormItems/FormItemSelect";
import { CREATE_GRADE } from "../../Graphql/Mutations";
import { Error } from "../../Types/MessageType";
import { FormInstance } from "antd/lib/form";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Image } from "antd";
//import Logo from '../../assets/IQdash_logo.svg';

// const gradesArr = [
//   { label: 'A', value: 'A' },
//   { label: 'B', value: 'B' },
//   { label: 'C', value: 'C' },
//   { label: 'D', value: 'D' }
// ];

type Attempt = {
  timestamp: string;
  timeTaken: number;
  passed: boolean;
};

type Scenario = {
  scenarioId: string;
  scenarioName: string;
  attempts: Attempt[];
};

type UserData = {
  userId?: string;
};

type createAnalyticsProp = {
  alertMessage: (val: Error) => void;
};

const CreateAnalytics = ({ alertMessage }: createAnalyticsProp) => {
  const resetAlertTimer = 3000;
  const location = useLocation();
  const [selectedScenario, setSelectedScenario] = useState<string>("");
  const [scenarios, setScenarios] = useState<Scenario[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [analyticData, setAnalyticData] = useState<any>([]);
  const [steps, setSteps] = useState<any>([]);
  const [selectedGradeId, setSelectedGradeId] = useState<string>();
  const [options, setOptions] = useState<any[]>([]);
 // const [grades, setGrades] = useState();
  const [form] = Form.useForm();
  const theme = useTheme();

  const [dropdownClicked, setDropdownClicked] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState<string>('');

  const [getUserById] = useLazyQuery(GET_USER_BY_ID, {
    onCompleted(data) {
      if (!!data.userWithRolesById) {
        form.setFieldsValue({
          roleCode: data.userWithRolesById.roles[0].roleName,
        });
        const orgId = data.userWithRolesById.organization;
        getOrganizationById({ variables: { organizationByIdId: orgId } });
      } else {
        alertMessage({ type: "error", message: "User not found" });
        setLoading(false);
      }
    }
  });

  const [getOrganizationById] = useLazyQuery(GET_ORG_BY_ID, {
    onCompleted(data) {
      if (!!data.organizationById) {
        const trainingPackage = data.organizationById.trainingPackage;
        if (trainingPackage) {
          const trainingPackageId = trainingPackage._id;
          getTrainingModuleById({ variables: { trainingPackageByIdId: trainingPackageId } });
        } else {
          alertMessage({ type: "error", message: "Training Package not found" });
          setLoading(false);
        }
      } else {
        alertMessage({ type: "error", message: "Organization not found" });
        setLoading(false);
      }
    }
  });

  const [getTrainingModuleById] = useLazyQuery(GET_TRAINING_MODULE__BY_ID, {
    onCompleted(data) {
      const trainingData = data.trainingPackageById;
      if (trainingData && trainingData.trainings && trainingData.trainings.length > 0) {
        const options = trainingData.trainings.map((training: any) => ({
          value: training._id,
          label: training.name
        }));
        console.log("Options:", options);
        setOptions(options);
      } else {
        alertMessage({ type: "error", message: "Trainings not found" });
        setLoading(false);
      }
    },
    onError(error) {
      alertMessage({ type: "error", message: error.message });
      setLoading(false);
    }
  });

  useEffect(() => {
    if (!!location.state?.userId) {
      getUserById(
        {
          variables: {
            userWithRolesByIdId: location.state?.userId
          }
        });
    }
  }, [location.state?.userId, getUserById]);

  const [getQuestionDetails] = useLazyQuery(GET_QUESTION_DETAILS, {
    onCompleted: (data) => {
      setScenarios(data?.getQuestionDetails || []);
      setLoading(false);
    },
    onError: (error) => {
      alertMessage({ type: "error", message: error.message });
      setLoading(false);
    }
  });

  useEffect(() => {
    if (moduleName) {
      getQuestionDetails({
        variables: {
          userId: (location.state as UserData)?.userId || '',
          moduleName: moduleName || '',
        },
      });
    }
  }, [moduleName, getQuestionDetails]);

  const createStepHandler = (selectedOption: any) => {
    const selectedTraining = options.find(option => option.value === selectedOption)?.label;
    if (selectedTraining) {
      setModuleName(selectedTraining);
      getQuestionDetails({
        variables: {
          userId: (location.state as UserData)?.userId || '',
          moduleName: selectedTraining || '',
        },
      });
    }
  };

  const setAlertMessageFun = (message: string, type: any) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    alertMessage({ type: type, message: message });
    setTimeout(() => {
      alertMessage({
        type: 'success',
        message: ''
      });
    }, resetAlertTimer);
  };

  const createStepHandlers = (selectedTraining: string, analyticData: any) => {
    analyticData.forEach((data: any) => {
      if (data.name === selectedTraining) {
        setSteps(data);
        setModuleName(selectedTraining);
        form.setFieldValue('grade', data?.grade[0]?.grade);
        setSelectedGradeId(data?.grade[0]?._id);
        getQuestionDetails({
          variables: {
            userId: (location.state as UserData)?.userId || '',
            moduleName: selectedTraining || '',
          },
        });
      }
    });
  };

  const setGradeHandlers = (grade: string, analyticData: any) => {
    if (!!selectedGradeId) {
      createGrade({
        variables: {
          gradingInput: {
            uniqueId: steps?.attempts[0]?.uniqueId,
            userId: location.state?.userId,
            grade: grade,
            _id: selectedGradeId
          }
        }
      });
    } else {
      createGrade({
        variables: {
          gradingInput: {
            uniqueId: steps?.attempts[0]?.uniqueId,
            userId: location.state?.userId,
            grade: grade,
          }
        }
      });
    }

  };


  const { loading: analyticsLoading } = useQuery(GET_USER_ANALYTICS, {
    variables: {
      analyticsByUserIdId: location.state?.userId
    },
    onCompleted(data) {
      const analytics = data?.analyticsByUserId;
      if (analytics.length > 0) {
        const _analytics = analytics.map((_singleAnalytics: any, index: number) => {
          return _singleAnalytics;
        });
        setAnalyticData(_analytics);
        let optionsData = _analytics.map((_analytic: any) => {
          return (
            {
              label: _analytic.name,
              value: _analytic.name
            }
          );
        });
        setOptions(optionsData);
        createStepHandlers(optionsData[0].label, _analytics);
      }
     // setGrades(gradesArr);
    },
    onError(error) {
      setAlertMessageFun(error.message, "error");
    }
  });

  const [createGrade, { loading: createGradeLoading }] = useMutation(CREATE_GRADE, {
    onCompleted() {
      setAlertMessageFun("Grade updated successfully", "success");
    },
    onError(error) {
      setAlertMessageFun(error.message, "error");
    }
  });

  const handleTabClick = (scenarioId: string) => {
    if (selectedScenario === scenarioId) {
      setSelectedScenario("");
    } else {
      setSelectedScenario(scenarioId);
    }
  };


  const showIconOfResult = (result: boolean) => {
    return (
      result ? <Tag color="success"><TaskAltIcon /></Tag> : <Tag color="error"><CancelIcon /></Tag>
    );
  };

  const setGradeHandler = (grade: string) => {
  };

  return (
    <>
      {options.some(option => option.label === 'NCLEX Module') && moduleName === 'NCLEX Module' ? (
        <FirstComponent
          form={form}
          options={options}
          //grades={grades}
          loading={loading}
          createGrade={createGrade}
          createGradeLoading={createGradeLoading}
          createStepHandler={createStepHandler}
          setGradeHandler={setGradeHandler}
          handleTabClick={handleTabClick}
          selectedScenario={selectedScenario}
          scenarios={scenarios}
          showIconOfResult={showIconOfResult}
          setSelectedScenario={setSelectedScenario}
          alertMessage={alertMessage}
          moduleName={moduleName}
          setModuleName={setModuleName}
        />
      ) : (
        <SecondComponent
          form={form}
          steps={steps}
          analyticData={analyticData}
          options={options}
          //grades={grades}
          theme={theme}
          loading={loading}
          createGrade={createGrade}
          createGradeLoading={createGradeLoading}
          createStepHandler={createStepHandler}
          analyticsLoading={analyticsLoading}
          setGradeHandlers={setGradeHandlers}
          handleTabClick={handleTabClick}
          selectedScenario={selectedScenario}
          scenarios={scenarios}
          showIconOfResult={showIconOfResult}
          setSelectedScenario={setSelectedScenario}
          moduleName={moduleName}
          setModuleName={setModuleName}
          alertMessage={alertMessage}
        />
      )}
    </>
  );
};

type FirstComponentProps = {
  form: FormInstance;
  options: any[];
 // grades: any[];
  loading: boolean;
  createGrade: any;
  createGradeLoading: boolean;
  createStepHandler: (selectedOption: any) => void;
  setGradeHandler: (grade: string) => void;
  handleTabClick: (scenarioId: string) => void;
  selectedScenario: string;
  scenarios: Scenario[];
  showIconOfResult: (result: boolean) => JSX.Element;
  setSelectedScenario: (scenarioId: string) => void;
  alertMessage: (val: Error) => void;
  moduleName: string; 
  setModuleName: (moduleName: string) => void;
};

const FirstComponent: React.FC<FirstComponentProps> = ({
  form,
  options,
//grades,
  createGradeLoading,
  createStepHandler,
  setGradeHandler,
  handleTabClick,
  selectedScenario,
  scenarios,
  showIconOfResult,
  setSelectedScenario,
  alertMessage,
  setModuleName
}) => (
  <>
    {
      !createGradeLoading ? (
        <Grid2 container spacing={2} sx={{ mx: 2, justifyContent: "space-between" }}>
          <Grid2 xs={12} md={8}>
            <Form form={form} initialValues={{ "trainingAnalytics": options[0]?.value, }} style={{ display: 'flex', gap: "50%" }}>
              <Box sx={{ flexGrow: 1 }}>
                <FormItemSelect
                  value={options}
                  name={'trainingAnalytics'}
                  label={'Training'}
                  showLable={false}
                  setValue={(v) => createStepHandler(v)} />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                {/* <FormItemSelect
                  value={grades}
                  name={'grade'}
                  label={'Grade'}
                  setValue={(v) => setGradeHandler(v)} /> */}
              </Box>
            </Form>
          </Grid2>
        </Grid2>
      ) : (
        <Box display={'flex'} justifyContent={'center'} sx={{ mt: 5 }}>
          <CircularProgress />
        </Box>
      )}

    <Grid2 container spacing={2}>
      {scenarios.map((scenario, i) => (
        <Paper elevation={2} sx={{ mx: 2, mb: 2 }} key={`paper-${scenario.scenarioId}`} style={{ width: '100%' }}>
          <Grid2 xs={12} key={scenario.scenarioId}>
            <div
              onClick={() => handleTabClick(scenario.scenarioId)}
              style={{ cursor: 'pointer', padding: '10px' }}
            >
              <Typography variant="subtitle1" style={{ display: 'inline-block', width: '200px' }}>{`ScenarioId: ${scenario.scenarioId}`}</Typography>
              <Typography variant="subtitle1" style={{ display: 'inline-block', width: 'calc(100% - 200px)' }}>{`Scenario: ${scenario.scenarioName}`}</Typography>
            </div>
          </Grid2>
          {selectedScenario === scenario.scenarioId && (
            <>
              {scenario.attempts.map((attempt, index) => (
                <Grid2 container padding={2} key={`attempt-${index}`}>
                  <Grid2 xs={6} sm={3}>
                    <Typography variant="subtitle1">{`Timestamp: ${attempt.timestamp}`}</Typography>
                  </Grid2>
                  <Grid2 xs={6} sm={3}>
                    <Typography variant="subtitle1">{`Time Taken: ${attempt.timeTaken}`}</Typography>
                  </Grid2>
                  <Grid2 xs={6} sm={3}>
                    {/* Render your video component here */}
                  </Grid2>
                  <Grid2 xs={6} sm={3}>
                    {showIconOfResult(attempt.passed)}
                  </Grid2>
                </Grid2>
              ))}
            </>
          )}
        </Paper>
      ))}
    </Grid2>
  </>
);

type SecondComponentProps = {
  form: FormInstance;
  options: any[];
  //grades: any[];
  steps: any;
  analyticData: any;
  loading: boolean;
  createGrade: any;
  theme: any;
  analyticsLoading: any;
  createGradeLoading: boolean;
  createStepHandler: (selectedOption: any) => void;
  setGradeHandlers: (grade: string, analyticData: any) => void;
  handleTabClick: (scenarioId: string) => void;
  selectedScenario: string;
  scenarios: Scenario[];
  showIconOfResult: (result: boolean) => JSX.Element;
  setSelectedScenario: (scenarioId: string) => void;
  moduleName: string;
  setModuleName: (moduleName: string) => void;
  alertMessage: (val: Error) => void;
};

const SecondComponent: React.FC<SecondComponentProps> = ({
  form,
  options,
 // grades,
  steps,
  theme,
  analyticData,
  analyticsLoading,
  loading,
  createGrade,
  createGradeLoading,
  createStepHandler,
  setGradeHandlers,
  handleTabClick,
  showIconOfResult,
  setSelectedScenario,
  moduleName,
  setModuleName,
  alertMessage,
}) => (
  <>
    {(!createGradeLoading && !analyticsLoading) ? <>
      <Grid2 container spacing={2} sx={{ mx: 2, justifyContent: "space-between" }}>
        <Grid2 xs={12} md={8} >
          <Form form={form} initialValues={{ "trainingAnalytics": options[0]?.value, }} style={{ display: 'flex', gap: "50%" }}>
            <Box sx={{ flexGrow: 1 }}>
              <FormItemSelect
                value={options}
                name={'trainingAnalytics'}
                label={'Training'}
                showLable={false}
                setValue={(v) => createStepHandler(v)} />
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              {/* <FormItemSelect
                value={grades}
                name={'grade'}
                label={'Grade'}
                setValue={(v) => setGradeHandlers(v, analyticData)} /> */}
            </Box>
          </Form>
        </Grid2>
        <Grid2>
          {
            steps?.attempts?.length > 0 && <Chip label={`Accuracy : ${parseFloat(steps?.attempts[0].accuracy).toFixed(2)}%`} variant="outlined" />
          }
        </Grid2>
      </Grid2>
      <Divider sx={{ mb: 2 }} />
      {
        steps?.attempts?.length > 0 ?
          steps?.attempts?.map((attempt: any) => (
            attempt.stats?.map((stat: any, i: number) => (
              <Paper elevation={2} sx={{ mx: 2, mb: 2 }} key={`key${i + 2}`}>
                <Grid2 container padding={2}>
                  <Grid2 xs={10} sm={8} container>
                    <Grid2 xs={2} sm={2}>
                      {`step ${stat?.stepNo}`}
                    </Grid2>
                    <Grid2 xs={10} sm={9}>
                      {stat?.stepName}
                    </Grid2>
                  </Grid2>
                  <Grid2 xs={6} sm={2}>
                    {/* {stat?.video} */}
                  </Grid2>
                  <Grid2 xs={6} sm={2}>
                    {
                      stat?.skipped ? <Tooltip title={'skipped'} color={theme.palette.primary.main}> <Tag color="default"><WarningAmberOutlinedIcon /></Tag> </Tooltip> : showIconOfResult(stat?.result)
                    }
                  </Grid2>
                </Grid2>
              </Paper>
            ))
          )) :
          <>
            <Box marginX={theme.spacing(60)}>
              <Paper elevation={0}>
                {/* <Image src={Logo} alt="logo" preview={false} width="100%" height={`70px`} /> */}
                <Typography variant="h6" paddingTop={theme.spacing(2)} textAlign={"center"}>
                  No data available
                </Typography>
              </Paper>
            </Box>
          </>
      }
    </> :
      <Box display={'flex'} justifyContent={'center'} sx={{ mt: 5 }}>
        <CircularProgress />
      </Box>}
  </>
);

export default CreateAnalytics;
