import { AppBar, IconButton, Toolbar, Typography, Box } from "@mui/material";
import Settings from "../Components/Settings";
import { LayoutProps } from "../Types/LayoutType";
import MenuIcon from '@mui/icons-material/Menu';
import dayjs from "dayjs";
import { useQuery } from "@apollo/client";
import { GET_ORG_BY_ID } from "../Graphql/Queries"; // Import the query to get organization by ID
import { useSelector } from "react-redux"; // Import Redux hook to access organization ID from state

const Header = ({ drawerWidth, mobileOpenBool, setMobileToggle }: LayoutProps) => {
  // Get organization ID from Redux state
  const orgId = useSelector((state: any) => state.auth.organization);

  // Skip query if orgId is not available
  const { data, loading, error } = useQuery(GET_ORG_BY_ID, {
    variables: { organizationByIdId: orgId },
    skip: !orgId,
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching organization data</p>;

  const organizationName = data?.organizationById?.name ;

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        background: 'linear-gradient(123.76deg, #F2709C 9.95%, #FF9472 86.42%)', // Apply linear gradient here
      }}
    >
      <Toolbar sx={{ height: '80px' }}> {/* Adjust the height of the Toolbar */}
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => setMobileToggle(!mobileOpenBool)}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Box sx={{ backgroundColor: '#ffffff'}}>
            <Typography variant="subtitle1" component="div" sx={{ color: '#000000' }}>
              {organizationName} {/* Display organization name */}
            </Typography>
          </Box>
          <Typography variant="subtitle1" noWrap component="div" sx={{ color: '#ffffff',marginTop:'10px' }}>
            {dayjs(Date.now()).format('dddd, D MMMM YYYY')}
          </Typography>
        </Box>
        <Settings />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
