import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GET_ALL_TRAININGS } from "../../Graphql/Queries";
import { TrainingType, TrainingTypeResponse } from "../../Types/QueriesType";
import { valuesArr } from "../../Types/SelectType";
import FormItemSelect from "../FormItems/FormItemSelect";

type TrainingComponentType = {
 showTrainings?: boolean;
 label: string;
 name: string;
};

const Training = ({ showTrainings = false, label, name }: TrainingComponentType) => {

 const [options, setOptions] = useState<valuesArr[]>([]);

 useQuery(GET_ALL_TRAININGS, {
  variables: {
   page: 0,
   limit: 200
  },
  onCompleted(data: TrainingTypeResponse) {
   const trainings = data.getAllTrainings.trainings.map((training: TrainingType): valuesArr => {
    return {
     label: training.name,
     value: training._id
    };
   });
   setOptions(trainings);
  }
 });


 return (
  <>
   <FormItemSelect label={label} name={name} value={options} setValue={(v) => null} />
  </>
 );
};

export default Training;