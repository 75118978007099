import { Form, Select } from "antd";
import { formSelectProp } from "../../Types/SelectType";

const FormItemSelect = ({ label, name, rules, value, mode = undefined, showLable = true, setValue }: formSelectProp) => {
 //const form = Form.useFormInstance();

 return (
  <Form.Item
   label={showLable ? label : null}
   name={name}
   rules={rules}
  //use onChange to reset error message
  >
   <Select
    showSearch
    mode={mode}
    placeholder={`Select ${label?.toLowerCase()}`}
    options={value}
    onSelect={(v) => { setValue!(v); }}
   />
  </Form.Item>
 );

};

export default FormItemSelect;