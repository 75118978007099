import { useSelector } from "react-redux";
import AdminDashboard from "../../Components/SubPages/AdminDashboard"
import SuperAdminDashboard from "../../Components/SubPages/SuperAminDashboard";
import { AdminPermissions } from "../../Constants/Permissions";

const Dashboard = () => {

 const { permissions } = useSelector((state: any) => state.auth)
 const { VIEW_DASHBOARD_A } = AdminPermissions

 return (
  <>
   {
    permissions.includes(VIEW_DASHBOARD_A) ? <AdminDashboard /> : <SuperAdminDashboard />
   }
  </>
 )

}

export default Dashboard;