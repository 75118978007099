
import { useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../Components/Cards/Card";
import DataTable from "../../Components/Datatable";
import Stats from "../../Components/Stats";
import TableHeading from "../../Components/TableHeading";
import { GET_USERS } from "../../Graphql/Queries";
import { SuperAdminPaths } from "../../Routes/Paths";
import { UsersType, UserWithRolesOrgType } from "../../Types/QueriesType";
import { useDebouncingValue } from "../../Utils/useDebouncingValue";
import { pages } from "../../Types/DatatableType";
import globals from "../../Constants/Globals";


type UserProps = {
 showCreateUser?: boolean;
 check?: boolean;
 showViewDetails?: boolean;
 formdata?: string[];
 tableFormItemName?: string;
 setUserIdArray?: (val: any[]) => void;
};

const Users = ({ showCreateUser = true, check = false, formdata, tableFormItemName, showViewDetails = true, setUserIdArray }: UserProps) => {

 const { CREATE_USER } = SuperAdminPaths;

 const [tableData, setTableData] = useState<any[]>([]);
 const [totalPages, setTotalPages] = useState<any>();
 const [currentPage, setCurrentPage] = useState<number>(1);
 const [totalUsers, setTotalUsers]=useState<any>(0);

 const [name,setName]=useState("");
 const [email,setEmail]=useState("");
 const [phoneNo,setPhoneNo]=useState("");
 const [role,setRole]=useState("");

const { debouncedValue: _name, debouncing: nameSearching } =useDebouncingValue(name);
const { debouncedValue: _email, debouncing: emailSearching } =useDebouncingValue(email);
const { debouncedValue: _phoneNo, debouncing: phoneNoSearching } =useDebouncingValue(phoneNo);
const { debouncedValue: _role, debouncing: roleSearching } =useDebouncingValue(role);

 const { organization, userId } = useSelector((state: any) => state.auth);
 const { loading: usersLoading } = useQuery<UserWithRolesOrgType>(GET_USERS, {
  variables: {
   userId:userId,
   orgId: organization,
   name:_name,
   email:_email,
   phoneNum:_phoneNo,
   role:_role,
   limit: globals.pageSize,
   page: currentPage
  },
  onCompleted(data) {
   if (data?.usersWithRolesByOrganizationId?.users.length > 0) {
    const users = data?.usersWithRolesByOrganizationId?.users;
    setTotalUsers(data.usersWithRolesByOrganizationId?.totalDocs);
    setTotalPages({
      currentPage: data?.usersWithRolesByOrganizationId?.currentPage,
      limit: globals.pageSize,
      totalDocs: data.usersWithRolesByOrganizationId?.totalDocs,
      totalPages: data.usersWithRolesByOrganizationId?.totalPages,
     });
     const orgUsers = users.map((user: UsersType, index: number) => {
      return {
       key: index,
       _id: user._id,
       name: user.name,
       email: user.email,
       phone: user.phone,
       organization: user.organization,
       roleID: user?.roles[0]?._id,
       roleCode: user?.roles[0]?.roleCode,
       roleName: user?.roles[0]?.roleName,
      };
     });
     setTableData(orgUsers);
  }else{
      setTableData([]);
      setTotalUsers(0);
    }
  }
 });

 const isLoading:boolean= usersLoading || nameSearching || emailSearching || phoneNoSearching || roleSearching;

 const columns: ColumnsType<any> = [
  {
   title: (
    <TableHeading heading="Name" setSearch={setName} setPage={setCurrentPage}/>
   ),
   dataIndex: "name",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
   key: "1",
  },
  {
   title: (
    <TableHeading heading="EmailId" setSearch={setEmail} setPage={setCurrentPage}/>
   ),
   dataIndex: "email",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
   key: "2",
  },
  {
   title: (
    <TableHeading heading="PhoneNumber" setSearch={setPhoneNo} setPage={setCurrentPage}/>
   ),
   dataIndex: "phone",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
   key: "3",
  },
  {
   title: (
    <TableHeading heading="Role" setSearch={setRole} setPage={setCurrentPage}/>
   ),
   dataIndex: "roleName",
   render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
   key: "4",
  },
  {
   title: '',
   render: (_, record) => showViewDetails && <Button variant="contained"><Link style={{ textDecoration: 'none', color: 'white' }} to={record._id} state={{ userId: record._id }}>View Details</Link></Button>,
   key: "5",
  }
 ];



 return (
  <>
   {
    showCreateUser && <Stats buttonText="Create New User" text="Total Users" number={totalUsers} link={CREATE_USER} />
   }
   <Card>
    <DataTable
     loading={isLoading}
     tableData={tableData}
     totalPages={totalPages}
     showCheck={check}
     columnData={columns}
     page={(cp) => {
      setCurrentPage(Number(cp))
     }}
     scrollX="max-content"
     formData={formdata}
     tableFormItemName={tableFormItemName}
     scrollY={450}
    />
   </Card>
  </>
 );

};

export default Users;