import { useTheme } from "@mui/material";
import { Tabs } from "antd";
import FormCard from "./Cards/FormCard";
import type { TabsProps } from 'antd';

type tabProps = {
  items: TabsProps['items']
  heading: string
  headingStyle?: React.CSSProperties
  keySelected?: string
  defaultKey: string
  selectedTab?: (val: boolean) => void
}

const CreateTabs = ({ items, heading, headingStyle, keySelected, defaultKey, selectedTab }: tabProps) => {
  const theme = useTheme();

  return (
    <FormCard heading={heading} headingStyle={headingStyle}>
      <Tabs
        items={items}
        size="small"
        type="card"
        activeKey={keySelected}
        onTabClick={(tab) => { tab === '2' ? selectedTab!(true) : selectedTab!(false) }}
        tabBarGutter={10}
        tabBarStyle={{
          backgroundColor: theme.palette.primary.dark,
          paddingTop: '0.2%',
          paddingLeft: '0.2%',
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px'
        }}
        defaultActiveKey={defaultKey}
        style={{
          backgroundColor: 'white',
          borderRadius: 10,
          minHeight: '600px'
        }} 
      />
    </FormCard>
  )
}

export default CreateTabs;