import React, { useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import { Typography, useTheme } from '@mui/material';

export default function BasicBreadcrumbs() {

 const location = useLocation();
 const [paths, setPaths] = React.useState<string[]>();
 const theme = useTheme();

 useEffect(() => {
  setPaths(location.pathname.split('/'));
 }, [location.pathname]);

 return (
  <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ color: theme.status.white }}>
   {
    paths?.map((path: string, index: number) => {
     return (
      index === paths.length - 1 ? <Typography key={path} variant='body1'>{path}</Typography> : <Link key={path} to={`/${path}`} style={{ color: theme.status.white, textDecoration: 'none' }}>{path}</Link>
     );
    })
   }
  </Breadcrumbs>
 );
}