
import { Paper } from "@mui/material";

const Card = (props: any) => {

 return (
  <>
   <Paper elevation={2} >
    {props.children}
   </Paper>
  </>
 )
}

export default Card;