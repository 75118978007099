
import { Form, TabsProps } from 'antd';
import { Box, Button, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CreateTabs from "../../Components/CreateTabs";
import { CREATE_COHORT } from '../../Graphql/Mutations';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Error } from '../../Types/MessageType';
import globals from '../../Constants/Globals';
import LoadingButton from '@mui/lab/LoadingButton';
import Message from '../../Components/Message';
import { useLocation, useNavigate } from 'react-router-dom';
import CreateCohortForm from '../../Components/SubPages/CreateCohortForm';
import Users from '../SuperAdmin/Users';
import { useSelector } from 'react-redux';
import { GET_COHORT_BY_ID } from '../../Graphql/Queries';
import { AdminPaths } from '../../Routes/Paths';


const { COHORT_PATH } = AdminPaths;

const CreateCohort = () => {

  const [tab, setTab] = useState<boolean>(true);
  // const [trainingId, setTrainingId] = useState<any[]>();
  const [selectedKey, setSelectedKey] = useState<string>('1');
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<Error>({ type: 'warning', message: '' });
  const [createCohort, { loading: loadingCohort }] = useMutation(CREATE_COHORT);
  const location = useLocation();
  const navigate = useNavigate();
  const { organization } = useSelector((state: any) => state.auth);
  const [getCohortById] = useLazyQuery(GET_COHORT_BY_ID, {
    variables: {
      getCohortByIdId: location.state?.cohortId
    },
    onCompleted(data) {
      const getCohortId = data.getCohortById;
      form.setFieldsValue(getCohortId);
    }
  });


  useEffect(() => {
    if (!!location.state?.cohortId) {
      getCohortById();
    }
  }, [location.state?.cohortId, getCohortById]);


  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <h3>Cohort Details</h3>,
      children: <CreateCohortForm alertMessage={alert} currentLocation={location.state?.cohortId ? true : false} />
      //setTrainingsArray={(trainingP: string[]) => setTrainingId(trainingP)}
    },
    {
      key: '2',
      label: <h3>User Details</h3>,
      children: <Form.Item name={"userIds"}><Users showViewDetails={false} showCreateUser={false} check={true} tableFormItemName={"userIds"} formdata={form.getFieldValue("userIds")} /></Form.Item>
    },
  ];


  const createCohortHandler = (value: any) => {
    let cohortFormData;
    if (!!location.state?.cohortId) {
      cohortFormData = { ...value, organization: organization, _id: location.state?.cohortId };
    } else {
      cohortFormData = { ...value, organization: organization };
    }

    createCohort({
      variables: {
        cohortInput: cohortFormData
      },
      onCompleted() {
        setAlert({ type: "success", message: "Cohort created successfully" });
        form.resetFields();
        navigate(COHORT_PATH);
        setTimeout(() => {
          setAlert({
            type: 'success',
            message: ''
          });
        }, globals.timeout);
      },
      onError(error) {
        setAlert({ type: 'error', message: error.message });
        setTimeout(() => {
          setAlert({
            type: 'error',
            message: ''
          });
        }, globals.timeout);
      }
    });
  };

  const showContinueButton =
    <Button
      onClick={() => { setSelectedKey('2'); setTab(false); }}
      sx={{ px: 5, py: 1,color: 'white',height: '45px' }}
      variant="contained" >
      Continue</Button>;

  const showSaveButton = !loadingCohort ?
    <>
      <Stack direction={'row'} spacing={2}>
        <Button
          sx={{ px: 5, py: 1,height: '45px',color:'white' }}
          variant="contained"
          onClick={() => { setSelectedKey('1'); setTab(true); }}>
          Back</Button>
        <Button
          sx={{ px: 5, py: 1,color: 'white',height: '45px' }}
          variant="contained"
          type='submit'>
          {location.state?.cohortId ? 'Update' : 'Save'}</Button>
      </Stack>
    </> :
    <>
      <LoadingButton
        sx={{ px: 5, py: 1 }}
        loading
        variant="contained">
        <span>Save</span>
      </LoadingButton>
    </>;

  return (
    <>
      <Form
        onFinish={createCohortHandler}
        form={form}
        layout={'vertical'}
        initialValues={
          {
            "userIds": form.getFieldValue("userIds")
          }
        }
      >
        <Box sx={{ mb: 3 }} display={'flex'} justifyContent={'flex-end'} >
          <Box flexGrow={1} sx={{ pr: 3 }}>
            {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
          </Box>
          {
            tab ? showContinueButton : showSaveButton
          }
        </Box>
        <CreateTabs items={items} heading={'Create Cohort'} headingStyle={{ color: 'black' }} keySelected={selectedKey} defaultKey={'1'} selectedTab={(tab) => null} />
      </Form>
    </>
  );
};

export default CreateCohort;