import { Box, Button, Paper, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom";

type StatProps = {
 buttonText?: string;
 text: string
 number: number
 link?: string
}

const Stats = ({ buttonText, text, number, link }: StatProps) => {

 const navigate = useNavigate();

 const createHandler = () => {
  if (!!link) navigate(link)
 }

 return (
  <Box>
   <Stack spacing={2} direction={window.innerWidth < 1024 ? "column" : "row"} sx={{ mb: { xs: 3, sm: 0 } }}>
    <Paper elevation={2} sx={{ px: 5, py: 3, mb: 3 }}>
     <Box>
      <Typography variant="h6" fontWeight="Bold">
       {text}
      </Typography>
     </Box>
     <Box>
      <Typography variant="h6" fontSize={'2.5rem'} fontWeight="Bold" color={'primary'}>
       {number}
      </Typography>
     </Box>
    </Paper>
    {!!link ? <Button onClick={createHandler} style={{ height: '45px', marginLeft: window.innerWidth < 1024 ? "0%" : "auto" }} sx={{ marginLeft: 2,color: 'white' }} variant="contained">
     {buttonText}
    </Button> : null}
   </Stack>
  </Box >
 )
}

export default Stats