
import { Box } from "@mui/material";

import React from "react";
import FormItemInput from "../FormItems/FormItemInput";
import FormListInput from "../FormItems/FormListInput";
import Grid2 from '@mui/material/Unstable_Grid2';
import { Error } from "../../Types/MessageType";


type createOrgProp = {
 alertMessage: Error;
};

const CreateHardwareDetailForm = ({ alertMessage }: createOrgProp) => {

 return (
  <>
   <Box sx={{ flexGrow: 1 }}>
    <Grid2 container spacing={2} paddingX={3}>
     <Grid2 xs={12} sm={6}>
      <FormItemInput label="User License Count" name="userLicenseCount" rules={[{ required: true, message: 'User license count is required' }]} type={'number'} />
     </Grid2>
     <Grid2 sm={12}>
      <FormListInput formControlName="serialNumber" label="Serial Number" rules={[{ required: true, message: 'Serial number is required' }]} type="text" gridSm={6} gridXs={12} />
     </Grid2>
    </Grid2>
   </Box>
  </>
 );
};

export default CreateHardwareDetailForm;