
import type { TabsProps } from 'antd';
import CreateUserForm from "../../Components/SubPages/CreateUserForm";
import { Box, Button, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import CreateTabs from "../../Components/CreateTabs";
import { useLocation } from 'react-router-dom';
import CreateAnalytics from '../../Components/SubPages/CreateAnalytics';
import { Error } from '../../Types/MessageType';
import Message from '../../Components/Message';




const CreateUser = () => {

 const createUserRef = useRef<any>(null);
 const [buttondisabled, setButtonDisable] = useState<boolean>(false);
 const [save, showSave] = useState<boolean>(true);
 const location = useLocation();
 const theme = useTheme();
 const [alert, setAlert] = useState<Error>();


 const items: TabsProps['items'] = [
  {
   key: '1',
   label: location.state?.userId ? <h3>Analytics</h3> : <h3 style={{ color: theme.palette.grey[500] }}>Analytics</h3>,
   children: <CreateAnalytics alertMessage={(message: Error) => setAlert(message)} />,
   disabled: location.state?.userId ? false : true
  },
  {
   key: '2',
   label: <h3>Personal Details</h3>,
   children: <CreateUserForm ref={createUserRef} disabledEv={(boolean: boolean) => setButtonDisable(boolean)} alertMessage={(message: Error) => setAlert(message)} />
  },
 ];

 return (
  <>
   <Box sx={{ mb: 3 }} display={'flex'} justifyContent={"space-between"} >
    <Box flexGrow={0} sx={{ pr: 3 }}>
     {!!alert?.message ? <Message type={alert?.type} message={alert.message} variant={"filled"} /> : null}
    </Box>
    <Box sx={{ mb: 3}} display={'flex'} justifyContent={'flex-end'}>
     <Button style={{ visibility: save ? 'visible' : 'hidden' }} disabled={buttondisabled} sx={{ px: 5, py: 1 ,color: 'white',height: '45px'}} variant="contained" onClick={() => createUserRef?.current?.submit()}>{location.state?.userId ? 'update' : 'save'}</Button>
    </Box>
   </Box>
   <CreateTabs items={items} heading={'User Details'} headingStyle={{ color: 'black' }} selectedTab={(tab) => showSave(tab)} defaultKey={location.state?.userId ? '1' : '2'} />
  </>
 );
};

export default CreateUser;