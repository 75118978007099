import { Typography } from "@mui/material"
import { Input } from "antd"

type props = {
 heading: string
 setSearch: (val: any) => void
 setPage: (val: any) => void
}

const TableHeading = ({ heading, setSearch, setPage }: props) => {
 return (
  <div>
   <Typography align="left">{heading}</Typography>
   <Input.Search
    onChange={(e) => {
     setSearch(e.target.value);
     setPage(1);
    }}
    placeholder="Search here"
   />
  </div>
 )
}

export default TableHeading