import { Form, Input, Radio } from "antd";
import { Rule } from "antd/es/form";

type formItemProp = {
 label: string
 name?: any
 rules?: Rule[]
 type: string
}

const FormItemInput = ({ label, name, rules, type }: formItemProp) => {

 //const form = Form.useFormInstance();

 return (
  <Form.Item
   label={label}
   name={name}
   rules={rules}
  //use onChange to reset error message
  >
   {type === 'radio' ? <Radio.Group>
    <Radio value={'male'}>Male</Radio>
    <Radio value={'female'}>Female</Radio>
   </Radio.Group> : <Input type={type} />}
  </Form.Item>
 )

}

export default FormItemInput;