
import { Box, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Error } from "../../Types/MessageType";
import { valuesArr } from "../../Types/SelectType";
import FormItemInput from "../FormItems/FormItemInput";
import FormItemSelect from "../FormItems/FormItemSelect";
import UploadImage from "../FormItems/UploadImage";
import Message from "../Message";


type createOrgProp = {
 alertMessage: Error;
};

const options: valuesArr[] = [
 {
  label: 'Easy',
  value: 'EASY'
 },
 {
  label: 'Medium',
  value: 'MEDIUM'
 },
 {
  label: 'Hard',
  value: 'HARD'
 }
];

const traningType: valuesArr[] = [
 {
  label: 'Practice',
  value: 'PRACTICE'
 },
 {
  label: 'Test',
  value: 'TEST'
 },
];

const CreateTrainingForm = ({ alertMessage }: createOrgProp) => {
 const theme = useTheme();

 return (
  <>
   <Grid2 container spacing={2} paddingX={3}>
    <Grid2 xs={12} sm={6}>
     <UploadImage name={'image'} label={'Upload Thumbnail'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Training Name" name="name" rules={[{ required: true, message: 'Training Name is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemSelect label="Type of training" name="typeOfTraining" rules={[{ required: true, message: 'Type of training is required' }]} value={traningType} setValue={(v) => null} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Training description" name="description" rules={[{ required: true, message: 'Training description is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemSelect label="Difficulty level" name="difficultyLevel" rules={[{ required: true, message: 'Difficulty level is required' }]} value={options} setValue={(v) => null} />
    </Grid2>
   </Grid2>
  </>
 );
};

export default CreateTrainingForm;