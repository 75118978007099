
enum CommonPermissions {
 VIEW_HOME = "view_home"
}


enum AdminPermissions {
 VIEW_DASHBOARD_A = "view_dashboard_Admin",
 VIEW_USERS = "view_users",
 VIEW_COHORT = "view_cohort",
 CREATE_COHORT = "create_update_cohort"
}

enum SuperAdminPermissions {
 VIEW_DASHBOARD_SA = "view_dashboard_Super_Admin",
 VIEW_ORGANIZATION = "view_organization",
 CREATE_USER_COMPANY_ADMIN="create_user_company_admin",
 CREATE_USER_COMPANY_MANAGER="create_user_company_manager",
 CREATE_USER_COMPANY_USER="create_user_company_user"
}

export { SuperAdminPermissions, AdminPermissions, CommonPermissions };
