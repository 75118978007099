
import { useQuery } from "@apollo/client";
import { Box, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { useState } from "react";
import { GET_ALL_TRAININGS } from "../../Graphql/Queries";
import { Error } from "../../Types/MessageType";
import { valuesArr } from "../../Types/SelectType";
import FormItemInput from "../FormItems/FormItemInput";
import FormItemSelect from "../FormItems/FormItemSelect";
import Message from "../Message";


type createOrgProp = {
 alertMessage: Error;
};

const CreateTrainingPackageForm = ({ alertMessage }: createOrgProp) => {
 const theme = useTheme();

 const [options, setOptions] = useState<valuesArr[]>([]);
 const { loading: trainingLoading } = useQuery<any>(GET_ALL_TRAININGS, {
  variables: {
   page: 0,
   limit: 1000
  },
  onCompleted(data) {
   if (data.getAllTrainings.trainings.length > 0) {
    const training = data?.getAllTrainings?.trainings;
    if (training.length > 0) {
     const _training = training.map((_singleT: any, index: number) => {
      return ({
       label: _singleT.name,
       value: _singleT._id
      });
     });
     setOptions(_training);
    }
   }
  },
  onError(err) {
   console.log(err.message);
  }
 });


 return (
  <>
   <Grid2 container spacing={2} paddingX={3}>
    <Grid2 xs={12} sm={6}>
     <FormItemInput label="Package Name" name="name" rules={[{ required: true, message: 'Package Name is required' }]} type={'text'} />
    </Grid2>
    <Grid2 xs={12} sm={6}>
     <FormItemSelect label="Select Trainings" name="trainings" rules={[{ required: true, message: 'Trainings are required' }]} mode="multiple" value={options} setValue={(v) => null} />
    </Grid2>
    {/* <Grid2 xs={12} sm={6}>
     <FormItemInput label="Package description" name="description" rules={[{ required: true, message: 'Package description is required' }]} type={'text'} />
    </Grid2> */}
   </Grid2>
  </>
 );
};

export default CreateTrainingPackageForm;