import { Box, Paper, Typography, useTheme } from "@mui/material";

type FormCardProps = {
  heading: string;
  headingStyle?: React.CSSProperties;
  children: React.ReactNode;
};

const FormCard = ({ heading, headingStyle, children }: FormCardProps) => {
  const theme = useTheme();
  return (
    <Paper elevation={2} sx={{ px: 3, py: 2, bgcolor: theme.palette.primary.light, minHeight: '600px' }}>
      <Box sx={{ m: 2 }}>
        <Typography variant="h6" color="primary" style={headingStyle}>
          {heading}
        </Typography>
      </Box>
      {children}
    </Paper>
  );
};

export default FormCard;
