import { Form, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import type { TableRowSelection } from 'antd/es/table/interface';
import { tableData } from '../Types/DatatableType';


const DataTable = (props: tableData) => {
 const form = Form.useFormInstance();
 const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>();
 const [pagination, setPagination] = useState({});
 const rowSelection: TableRowSelection<any> = {
  selectedRowKeys: selectedRowKeys,
  onChange: (selectedRowKeys, selectedRows) => {
   if (props.rowdata) {
    props.rowdata(selectedRows);
   }
   setSelectedRowKeys(selectedRowKeys);
   if (props.tableFormItemName) {
    form.setFieldValue(props.tableFormItemName, selectedRows.map((selectedRow: any) => selectedRow._id));
   }
  },
 };
 const scrollValues = {
  x: props.scrollX,
  y: props.scrollY,
 };

 useEffect(() => {
  if (props.formData) {
   setSelectedRowKeys(props.tableData!.map((data: any, index: any) => {
    for (const fd of props.formData!) {
     if (data._id === fd) {
      return index;
     }
    }
   })
   );
  }
 }, [props.formData, props.tableData]);

 useEffect(() => {
  setPagination({
   pageSize: props.totalPages?.limit,
   total: props.totalPages?.totalDocs,
   current: props.totalPages?.currentPage,
   position: props.totalPages?.position,
   onChange: (pageNumber: string) => {
    if (props.page) {
     props.page(pageNumber);
    }
   },
   showSizeChanger: false
  });
 }, [props, props.tableData, props.totalPages]);

 return (
  <Table loading={props.loading !== undefined ? props.loading : false} rowSelection={props.showCheck ? {
   ...rowSelection
  } : undefined} scroll={scrollValues} onRow={(record) => {
   return {
    onClick: () => {
     if (!!record && !!props.onRow) {
      props.onRow(record);
     }
    },
    style: { cursor: 'pointer' }
   };
  }} columns={props.columnData} pagination={props.totalPages !== undefined ? pagination : true} dataSource={props.tableData} />
 );
};

export default DataTable;