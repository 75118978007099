import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { Provider } from 'react-redux';
import Store from './Store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import client from './Utils/apolloClient';
import { ApolloProvider } from '@apollo/client';

const theme = createTheme({
  status: {
    position: 'relative',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100vh',
    justifyContentCenter: 'center',
    errorMessageSpace: '50px',
    white: '#ffffff',
    black: '#000000',
    drawerWidth: 240,
  },
  palette: {
    background: {
      default: '#FEE8E4',
    },
    primary: {
      main: '#FA969D',
      light: '#EEEEEE',
      dark: '#FA969D'
    },
    action: {
      hover: '#FA969D',
      selected: '#FA969D',
      selectedOpacity: 0.8
    },
  },

})


let persistor = persistStore(Store);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <PersistGate persistor={persistor}>
            <App />
          </PersistGate>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
