import { useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { ColumnsType } from "antd/es/table";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Card from "../../Components/Cards/Card";
import DataTable from "../../Components/Datatable";
import Stats from "../../Components/Stats";
import TableHeading from "../../Components/TableHeading";
import globals from "../../Constants/Globals";
import { GET_ALL_COHORTS, GET_USER_BY_ID, GET_COHORT_BY_ID } from "../../Graphql/Queries";
import { AdminPaths } from "../../Routes/Paths";
import { pages } from "../../Types/DatatableType";

const Cohort = () => {
  const { CREATE_COHORT_PATH } = AdminPaths;

  const [tableData, setTableData] = useState<any[]>([]);
  const [totalPages, setTotalPages] = useState<pages>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isRootAdmin, setIsRootAdmin] = useState<boolean>(false);
  const [trainingPackageId, setTrainingPackageId] = useState<string | null>(null);

  const { userId, organization } = useSelector((state: any) => state.auth);

  const { data: userData, loading: userLoading } = useQuery(GET_USER_BY_ID, {
    variables: { userWithRolesByIdId: userId },
    onCompleted(data) {
      const roles = data?.userWithRolesById?.roles || [];
      const isAdmin = roles.some((role: any) => role.roleCode === "ROOT_ADMIN");
      setIsRootAdmin(isAdmin);
      const orgId = data?.userWithRolesById?.organization || null;
      setTrainingPackageId(orgId); // Assuming orgId is the training package ID, adjust if necessary
    },
    onError(err) {
      console.error("Error fetching user data:", err.message);
    }
  });

  // Query for root admin to fetch all cohorts
  const { loading: cohortsLoading } = useQuery(GET_ALL_COHORTS, {
    skip: !isRootAdmin,
    variables: {
      orgId: organization
    },
    onCompleted(data) {
      if (data.getAllCohorts.length > 0) {
        const cohorts = data?.getAllCohorts;
        setTableData([]);
        if (cohorts.length > 0) {
          const _cohorts = cohorts.map((_singleCohort: any, index: number) => {
            return {
              key: index,
              id: _singleCohort._id,
              cohortName: _singleCohort.cohortName,
              startDate: _singleCohort.cohortStartDate,
              noOfUsers: _singleCohort.userIds.length,
              noOfTrainings: _singleCohort.cohortModule.length, // Assuming cohortModule is an array
            };
          });
          setTableData(_cohorts);
        }
      }
    },
    onError(err) {
      console.log("Error fetching all cohorts:", err.message);
    },
  });

  const { loading: cohortLoading } = useQuery(GET_COHORT_BY_ID, {
    skip: isRootAdmin || !trainingPackageId,
    variables: { getCohortByIdId: trainingPackageId },
    onCompleted(data) {
      console.log("Cohort data fetched:", data);
      const cohort = data?.getCohortById;
      if (cohort) {
        const _cohorts = [{
          key: 0,
          id: cohort._id,
          cohortName: cohort.cohortName,
          startDate: cohort.cohortStartDate,
          noOfUsers: cohort.userIds.length,
          noOfTrainings: cohort.cohortTrainings.length,
        }];
        setTableData(_cohorts);
      }
    },
    onError(err) {
      console.log("Error fetching cohort:", err.message);
    },
  });

  const columns: ColumnsType<any> = [
    {
      title: (
        <TableHeading heading="Cohort Name" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "cohortName",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "1",
      width: "20%"
    },
    {
      title: (
        <TableHeading heading="Start Date" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "startDate",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "2",
      width: "20%"
    },
    {
      title: (
        <TableHeading heading="No of Users" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "noOfUsers",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "3",
      width: "20%"
    },
    {
      title: (
        <TableHeading heading="No of Trainings" setSearch={(e) => console.log(e)} setPage={setCurrentPage} />
      ),
      dataIndex: "noOfTrainings",
      render: (text) => <Typography variant="body1" sx={{ textAlign: 'left' }}>{text}</Typography>,
      key: "4",
      width: "20%"
    },
    {
      title: '',
      render: (_, record) => <Button variant="contained" sx={{ alignSelf: 'flex-end' }}><Link style={{ textDecoration: 'none', color: 'white' }} to={record.id} state={{ cohortId: record.id }}>View Details</Link></Button>,
      key: "5",
      width: "20%"
    }
  ];

  const loading = userLoading || (isRootAdmin ? cohortsLoading : cohortLoading);

  return (
    <>
      <Stats buttonText="Create Cohort" text="Total Cohorts" number={tableData.length} link={CREATE_COHORT_PATH} />
      <Card>
        <DataTable
          loading={loading}
          tableData={tableData}
          totalPages={totalPages}
          showCheck={false}
          columnData={columns}
          page={(cp) => {
            setCurrentPage(Number(cp));
          }}
          scrollX="max-content"
          scrollY={450}
        />
      </Card>
    </>
  );
};

export default Cohort;